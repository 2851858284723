import {
  afterNextRender,
  Component,
  Inject,
  Optional,
  PLATFORM_ID,
  TransferState,
  ViewChild,
} from '@angular/core';
import {BaseResponse, Option, ThemeOption} from '@/app/shared/interface/common.interface';
import {CartService} from '../shared/services/cart.service';
import {CommonService} from '../shared/services/common.service';
import {Cart} from '../shared/interface/cart.interface';
import {HomeConfigurationService} from '../shared/services/homeconfiguration.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
// import $ from 'jquery';
import {Observable, Subject, firstValueFrom} from 'rxjs';
import {CategoryService} from '../shared/services/category.service';
import {ProvinceService} from '../shared/services/province.service';
import {
  AuthService,
  COOKIES,
  DEFAULT_VERSION,
  IS_ACCOUNT_ACTIVE,
  IS_AUTHENTICATED,
  REFRESH_TOKEN,
  TOKEN,
  CURRENT_VERSION,
} from '../shared/services/auth.service';
import {Category} from '../shared/interface/category.interface';
import {SeoService} from '../shared/services/seo.service';
import {DOCUMENT, isPlatformBrowser, isPlatformServer} from '@angular/common';
import {SsrCookieService} from 'ngx-cookie-service-ssr';
import {REQUEST} from '@/express.tokens';
import {jwtDecode} from 'jwt-decode';
import {SignalrService} from '../shared/services/notification.service';
import {FlashSaleService} from '../shared/services/flashsale.service';
import {Flashsale} from '../shared/interface/flashsale.interface';
import {AccountService} from '../shared/services/account.service';
import {User} from '../shared/interface/user.interface';
import {GiftService} from '../shared/services/gift.service';
import {RegistryModalComponent} from '../shared/widgets/registry-modal/registry-modal.component';
import {WheelService} from '../shared/services/wheel.service';
import { CheckAvailableFeatureService} from "@/app/shared/services/checkavailablefeature.service";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
})
export class LayoutComponent {
  @ViewChild("registryModal") RegistryModal: RegistryModalComponent;

  themeOption: Option;
  sticky: boolean = true;
  isAuthenticated: boolean = false;
  public isPopup: boolean = false;
  public isBrowser: boolean = false;

  public PopupData: any = {
    link: '',
    src: '',
  };

  display = true;
  cookies: string;

  constructor(
    private cartService: CartService,
    private commonService: CommonService,
    private categoryService: CategoryService,
    private provinceService: ProvinceService,
    private homeConfigurationService: HomeConfigurationService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private seoService: SeoService,
    private cookieService: SsrCookieService,
    private flashsaleservice: FlashSaleService,
    private accountService: AccountService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(REQUEST) private request: any,
    private transferState: TransferState,
    private notificationService: SignalrService,
    private giftService: GiftService,
    private wheelService: WheelService,
    private checkAvailableFeatureService: CheckAvailableFeatureService
  ) {
    this.isAuthenticated = this.authService.isAuthenticated();
    if (isPlatformServer(this.platformId)) {
      this.display = false;
    } else {
      if (this.isAuthenticated) {
        this.notificationService.startConnection();
      }
    }

    afterNextRender(() => {
      this.route.queryParams.subscribe({
        next: (params: any) => {
          if (params && params["ref"]) {
            if (!this.isAuthenticated) {
              localStorage.setItem('ref', params["ref"]);
              this.RegistryModal.openModal();
            } else {
              this.router.navigate(['/']);
            }
          }
        },
      });
    });
  }

  ngOnInit() {
    this.getHomeConfiguration();

    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;

      const token = this.transferState.get(TOKEN, '');
      this.cookies = this.transferState.get(COOKIES, '');
      if (token) {
        this.getCarts();
        this.getUser();

        //this.getFlashsale();
        //this.getGiftInfo();

        this.checkAvailableMinigameWheel();


        const refreshToken = {
          refreshToken: token,
        };

        this.authService.refreshToken(JSON.stringify(refreshToken));
      }

      this.getCategories();
      this.getPopupBannerInfo();

      this.isPopup = this.getCookiePopup();

      this.commonService.authEvent.subscribe({
        next: async (data: boolean) => {
          if (data) {
            this.notificationService.startConnection();
            this.cartService.getTranferMethods();
            this.getCarts();
            this.getUser();
            this.checkAvailableMinigameWheel();
          } else {
            // this.commonService.setCart(new Cart());
            let res = localStorage.getItem('cart');
            if (res) {
              this.commonService.setCart(JSON.parse(res));
            } else {
              this.commonService.setCart(new Cart());
            }
            this.cartService.setUserTransportData([]);
            this.accountService.setUserData(new User());
            this.wheelService.setWheelConfig({});
          }
          this.isAuthenticated = data;
        },
      });

      window.onbeforeunload = () => {
        this.notificationService.stopConnection();
      }

      window.onoffline = () => {
        this.notificationService.stopConnection();
      }
    }
  }

  checkAvailableMinigameWheel() {
    this.checkAvailableFeatureService.checkAvailableMinigameWheel().subscribe({
      next: (res: any) => {
        if(res && res == true) {
          this.getWheelConfig();
        }
        this.wheelService.setWheelConfig({});
      },
      error: (error: any) => {
        this.wheelService.setWheelConfig({});
        console.log(error);
      },
    })
  };

  getPopupBannerInfo() {
    this.homeConfigurationService.getPopupBannerInfo().subscribe((res: any) => {
      this.PopupData = res;
    });
  }

  getCarts() {
    this.cartService.getCarts().subscribe((res: any) => {
      if (res) {
        this.commonService.setCart(res);
      }
    });
  }

  getCategories() {
    this.categoryService.getCategories().subscribe((res: any) => {
      if (res) {
        this.commonService.setCategories(res);
      }
    });
  }

  setLogo() {
    var headerLogo = 'assets/images/DuocVuong-white-logo.png';
    var footerLogo = '';
    if (this.themeOption) {
      var headerLogo = this.themeOption.logo.header_logo_url;
      var footerLogo = '';
    }

    return {header_logo: headerLogo, footer: {footer_logo: footerLogo}};
  }

  getCookiePopup() {
    var nameEQ = 'isPopup=';
    var ca = this.cookies.split(';');
    if (ca) {
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        if (c.indexOf(nameEQ) != -1) {
          var cc = c.split('=');
          return cc[1].toLowerCase() === 'true';
        }
      }
      return true;
    }

    return false;
  }

  getHomeConfiguration() {
    this.route.data.subscribe({
      next: (res: any) => {
        if (res && res.homeConfig) {
          this.themeOption = res.homeConfig.options;
          this.seoService.generateTags(
            {
              image: this.themeOption.seo.og_image.original_url,
              title: this.themeOption.seo.meta_title,
              metaTitle: this.themeOption.seo.meta_title,
              description: this.themeOption.seo.meta_description
            }
          );

          const faviconUrl = res.homeConfig.options?.logo?.favicon_icon?.original_url;
          if (faviconUrl) {
            this.document
              .getElementById('favicon')!
              .setAttribute('href', faviconUrl);
          }
        }
      }
    });
  }

  getFlashsale() {
    this.flashsaleservice.getFlashSale().subscribe({
      next: (res: Flashsale) => {
        if (res) {
          this.flashsaleservice.setFlashSaleEvents(res);
        }

      },
      error: (error) => {
        console.log(error);
      },
    });

  }

  getGiftInfo() {
    this.giftService.getGiftInfomation().subscribe({
      next: (res: any) => {
        this.giftService.setGiftInfo(res);
      },
      error: (error: any) => {
        console.log(error);
      },
    })
  }

  getUser() {
    this.accountService.getUser().subscribe({
      next: (res: User) => {
        // this.giftService.getTotalGiftPoint().subscribe({
        //   next: (giftPoint: any) => {
        //     res.giftPoint = giftPoint.totalPoint;
        //     this.accountService.setUserData(res);
        //   },
        //   error: (error: any) => {
        //     console.log(error);
        //   },
        // });

        this.accountService.setUserData(res);
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  }

  closePopup() {
    var startDate = new Date();
    var endDate = new Date().setDate(startDate.getDate() + 1);
    this.document.cookie = 'isPopup=false; expires=' + endDate + '; path=/';
    this.isPopup = false;
  }

  getWheelConfig() {
    this.wheelService.getWheel().subscribe({
      next: (res: any) => {
        if (res && res.data) {
          this.wheelService.setWheelConfig(res.data);
        } else {
          this.wheelService.setWheelConfig({});
        }
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }
}
