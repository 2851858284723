import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, TransferState } from "@angular/core";
import { environment } from "@/environments/environment";
import { BehaviorSubject } from "rxjs";
import { Breadcrumb } from "../interface/breadcrumb";
import { Affiliate, User } from "../interface/user.interface";
import { CommonService } from '@/app/shared/services/common.service';
import { DOCUMENT } from "@angular/common";
import { SsrCookieService } from "ngx-cookie-service-ssr";
import { TOKEN } from "./auth.service";
import { BaseResponse } from "../interface/common.interface";

@Injectable({
  providedIn: "root",
})

export class CheckAvailableFeatureService {
  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    @Inject(DOCUMENT) private document: Document,
    private cookieService: SsrCookieService,
    private transferState: TransferState
  ) { }


  checkAvailableMinigameWheel() {
    return this.http.get<BaseResponse>(`${environment.baseURL}check-available-feature/wheel`);
  }
}
